import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Modal from '../../Modal';
import { resetCurrentModal } from '../../../actions';
import * as ROUTES from '../../../constants/routes';
import { nextTopicRoute } from '../../../services/topics';

class TopicComplete extends Component {
  componentWillUnmount() {
    this.handleClose();
  }

  handleFieldChange = (e) => {
    const { name, value } = e.currentTarget;

    this.setState({
      [name]: value
    });
  }

  handleClose = () => {
    this.props.resetCurrentModal();
  }

  render() {
    const {
      currentModal, currentTopic, topics, course, history
    } = this.props;

    if (!course?.id || !currentTopic?.id || !topics) return null;

    const nextTopicRouteStr = nextTopicRoute({ course, currentTopic, topics });

    return (
      <Modal
        cssClassName={`turbine-modal--${currentModal?.key}`}
        visible={currentModal.visible}
        close={nextTopicRouteStr ? false : this.handleClose}
        pos="top"
      >
        <div className='py-5'>
          <div className="d-flex justify-content-center align-items-center flex-column">
            <h4 className="font-weight-bold mt-3 text-center">
              Topic Complete
            </h4>
          </div>
          <div className="mt-3 text-center">
            <p>
              <i className="far fa-check-circle text-keppel" /> Good job! You completed the topic,
              <br />
              <b>{currentTopic.title}</b>.
            </p>
            <div>
              <div className="font-weight-bold">
                Next steps:
              </div>

              {nextTopicRouteStr ? (
                <Fragment>
                  <p>
                    Continue to the next topic:
                  </p>
                  <button
                    title="Next Topic"
                    className="btn btn-md btn-primary"
                    onClick={() => {
                      this.handleClose();
                      history.push(nextTopicRouteStr);
                    }}
                    type="button"
                  >
                    Next Topic
                  </button>
                </Fragment>
              ) : (
                <Fragment>
                  <p>
                    Checkout other courses in the catalog.
                  </p>
                  <button
                    title="Course Catalog"
                    className="btn btn-md btn-primary"
                    onClick={() => {
                      this.handleClose();
                      history.replace(ROUTES.CLASSES);
                    }}
                    type="button"
                  >
                    Course Catalog
                  </button>
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = ({
  organization,
  currentModal,
  currentClass,
  currentTopic,
  topics
}) => ({
  organization,
  currentModal,
  course: currentClass,
  currentTopic,
  topics
});

export default compose(
  withRouter,
  connect(mapStateToProps, { resetCurrentModal })
)(TopicComplete);
