export const GMAIL_SEND = '/v1/external/gmail/send';
export const WEATHER_BASE_URL = '/v1/external/weather';
export const FIREBASE_STORE_COLLECTION = '/v1/external/firebase/store';
export const SLACK_USERS_LOOKUP_BY_EMAIL = '/v1/external/slack/usersLookupByEmail';
export const SLACK_CONVO_INVITE = '/v1/external/slack/conversationsInvite';
export const SLACK_REPORT_ISSUE_CHANNEL = '/v1/external/slack/reportIssue';
// Supabase
export const SUPABASE_BASE_URL = '/external/supabase';
// AWS
export const API_AWS_UPLOAD = '/v1/external/aws/upload';
// PAGINATION
export const DEFAULT_PAGINATION_LIMIT = 20;
export const NOTIFICATIONS_PAGINATION_LIMIT = 20;
export const QUIZ_OUTCOMES_PAGINATION_LIMIT = 200;
