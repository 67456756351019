import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { ERROR_ACCOUNT_INACTIVE } from '@apprentage/constants';
import { isLocalhost } from '../../serviceWorker';
import { isActiveMembership, membershipEligible } from '../../services/currentUser';
import { setCurrentAnswer } from '../../actions/Answers';
import { getOrg } from '../../actions/Organizations';
import { setCurrentUser } from '../../actions/Users';
import { setLoading, setCurrentPage } from '../../actions/Session';
import { removeToast, addToast } from '../../actions/Toasts';
import { alreadyJoinedWorkspace } from '../../actions/Slack';
import { setCurrentModal } from '../../actions/Modals';
import { setCurrentClass } from '../../actions/Class';
import { withFirebase } from '../Firebase';
import {
  BILLING, CLASSES, PROFILE, SSO
} from '../../constants/routes';
import { TURBINE } from '../../constants/urls';
import { INTEGRATE_SLACK } from '../../constants/toasts';
import { redirectToAuth } from '../../services/auth';
import { fetchUser } from '../../services/users';
import replaceAll from '../../utils/replaceAll';
import convertObjectToUrlParams from '../../utils/convertObjectToUrlParams';
import convertUrlParamsToObject from '../../utils/convertUrlParamsToObject';
import isPublished from '../../utils/isPublished';
import Loading from '../Loading';

let hidden = null;
let visibilityChange = null;
if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
  hidden = 'hidden';
  visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden';
  visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden';
  visibilityChange = 'webkitvisibilitychange';
}

const currentPageReadable = ({ pathname, course }) => {
  const pathnameArr = pathname.split('/');

  if (pathnameArr[1] === '' && (pathnameArr[0] === '' || '/')) {
    return 'Dashboard';
  }

  const humanReadablePathname = replaceAll(pathnameArr[1], '-', ' ');

  switch (humanReadablePathname) {
    case 'classes': { // TODO change to courses
      if (isPublished(course)) {
        return course.title;
      }

      return humanReadablePathname;
    }
    default:
      return humanReadablePathname;
  }
};

const createSearchQuery = (searchParams) => {
  const { location: { href, search } } = window;
  const baseUrl = href.replace(search, '');
  const searchQuery = { token: searchParams.token };
  const continueUrlParams = { ...searchParams };

  delete continueUrlParams.token; // Don't pass along token in continueUrl

  searchQuery.continueUrl = encodeURIComponent(baseUrl + convertObjectToUrlParams(continueUrlParams));

  return convertObjectToUrlParams(searchQuery);
};

const withAuthorization = (condition) => (Component) => {
  class WithAuthorization extends React.Component {
    componentDidMount() {
      const { match } = this.props;
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      document.addEventListener(visibilityChange, this.handleVisibilityChange, false);
      // window.addEventListener('focus', this.handleOnFocus, false);

      const {
        organization,
        location: { search: locationSearch }
      } = this.props;
      const searchParams = convertUrlParamsToObject(locationSearch);
      const hasToken = searchParams && searchParams.token;

      this.listener = this.props.firebase.onAuthUserListener((response) => {
        if (!condition(response.authUser)) {
          if (hasToken) {
            this.goToSSO(searchParams);
          } else {
            redirectToAuth({});
          }
        }
        const currentUserOrgId = response?.currentUser?.orgId || '';

        if (organization) {
          this.props.setCurrentUser(response.currentUser);
          this.currentPageActions({
            currentUser: response.currentUser,
            organization
          });
        } else {
          this.props.getOrg({ orgId: currentUserOrgId }).then((org) => {
            this.props.setCurrentUser(response.currentUser);
            this.currentPageActions({
              currentUser: response.currentUser,
              organization: org
            });
          });
        }
      }, () => {
        if (hasToken) {
          this.goToSSO(searchParams);
          return;
        }

        if (window.location.pathname.includes(CLASSES) && match?.params?.classId) {
          this.props.setCurrentClass({ classId: match?.params?.classId }).then((responseCourse) => {
            const courseReadOnly = responseCourse?.currentClass?.courseReadOnly || false;
            const courseOrgId = responseCourse?.currentClass?.orgId || '';

            this.props.getOrg({ orgId: courseOrgId });

            if (!courseReadOnly) {
              if (organization?.slug) {
                redirectToAuth({ organizationSlug: organization?.slug });
                return;
              }

              redirectToAuth({});
            }
          });

          return;
        }

        if (organization?.slug) {
          redirectToAuth({ organizationSlug: organization?.slug });
          return;
        }

        redirectToAuth({});
      });
    }

    componentWillUnmount() {
      this.listener();
    }

    goToSSO = (searchParams) => {
      const search = createSearchQuery(searchParams);
      const ssoUrl = TURBINE + SSO + search;

      window.location = ssoUrl;
    }

    checkSlackAccount = (email) => {
      const { currentUser } = this.props;

      if (!currentUser) return null;

      const { id: userId } = currentUser;

      this.props.alreadyJoinedWorkspace({ email, userId }).then(() => {
        this.removeSlackToast();
      }).catch(() => {
        this.props.addToast(INTEGRATE_SLACK);
      });
    }

    removeSlackToast = () => {
      this.props.removeToast(INTEGRATE_SLACK.id);
    }

    getData = () => {
      const { currentUser, currentEntry } = this.props;

      if (!currentUser) return null;

      if (this.skipGetData()) return null;

      const userId = currentUser?.id;
      const orgId = currentUser?.orgId;

      const {
        match: { params: { classId } },
        currentAnswer
      } = this.props;

      if (!document[hidden] && !isLocalhost) {
        if (classId && currentAnswer && currentEntry) {
          this.props.setCurrentAnswer(currentAnswer.id);
        }

        fetchUser({ userId }).then((currentUserResponse) => {
          if (currentUserResponse) {
            this.props.getOrg({ orgId }).then((response) => {
              this.currentPageActions({
                currentUser: currentUserResponse,
                organization: response.organization
              });
              this.props.setCurrentUser(currentUserResponse);
            });
          }
        });
      }
    }

    handleVisibilityChange = () => {
      this.getData('visibility');
    }

    handleOnFocus = () => {
      this.getData('focus');
    }

    skipGetData() {
      const { currentPage } = this.props;

      switch (currentPage) {
        case 'calendar':
        case 'billing':
        case 'profile':
          return true;
        default:
          return false;
      }
    }

    currentPageActions({ currentUser, organization }) { // TODO why not get currentUser from props
      const {
        course,
        location: { pathname }
      } = this.props;

      // Current User
      const phone = currentUser?.phone || '';
      const mobilePhone = currentUser?.mobilePhone || '';
      const email = currentUser?.email || '';
      const slackUserId = currentUser?.slackUserId || '';
      const isApprentice = currentUser?.isApprentice || false;
      const userProfileId = currentUser?.userProfileId || '';
      const updatePaymentMethod = currentUser?.updatePaymentMethod || false;
      const membershipReadable = currentUser?.membershipReadable || '';
      const hasPhone = phone || mobilePhone;
      // Organization
      const orgType = organization?.type;
      const organizationSlug = organization?.slug;
      const integrateSlack = organization?.integrateSlack || false;

      if (isApprentice && (!userProfileId || !hasPhone)) {
        if (window.location.pathname !== PROFILE) {
          window.location = PROFILE;
        }

        return false;
      }

      /**
       * INTEGRATE SLACK
       */
      if (integrateSlack && !slackUserId && membershipEligible(membershipReadable, 'slack')) {
        this.checkSlackAccount(email);
      } else {
        this.removeSlackToast();
      }
      // /INTEGRATE SLACK

      // TODO isActiveMembership is not triggered after membership Changes without a page refresh
      // TODO use pattern that billingAgreement is using with modal, etc.
      if (updatePaymentMethod) {
        return this.props.history.replace(BILLING);
      }

      const readableCurrentPage = currentPageReadable({ pathname, course });

      // User is inactive
      if (!isActiveMembership(membershipReadable)) {
        // If Enterprise & user is inactive, log out.
        if (orgType && orgType === 'workforce') {
          redirectToAuth({
            ...(organizationSlug ? { organizationSlug } : {}),
            error: {
              code: ERROR_ACCOUNT_INACTIVE
            }
          });
          return;
        }
        // If any page other than attendance || certification redirect to PROFILE
        if (readableCurrentPage !== 'attendance' && readableCurrentPage !== 'certificate') {
          return this.props.history.replace(PROFILE);
        }
      }

      if (this.props.currentPage !== readableCurrentPage) {
        this.props.setCurrentPage(readableCurrentPage);
      }
    }

    render() {
      const {
        user, currentUser, organization, course
      } = this.props;

      if (course?.id && course?.courseReadOnly && organization?.id) {
        return <Component {...this.props} />;
      }

      if (condition(user) && currentUser?.id && organization?.id) {
        return <Component {...this.props} />;
      }

      return (
        <Loading className="position-fixed" />
      );
    }
  }

  const mapStateToProps = ({
    user,
    currentUser,
    currentClass,
    currentEntry,
    organization,
    currentAnswer,
    currentPage
  }) => ({
    user,
    currentUser,
    course: currentClass,
    currentEntry,
    organization,
    currentAnswer,
    currentPage
  });

  return compose(
    withRouter,
    withFirebase,
    connect(mapStateToProps, {
      addToast,
      removeToast,
      setCurrentAnswer,
      setCurrentClass,
      setLoading,
      setCurrentUser,
      setCurrentPage,
      getOrg,
      alreadyJoinedWorkspace,
      setCurrentModal
    })
  )(WithAuthorization);
};

export default withAuthorization;
