import { pathOr } from 'ramda';
import {
  updateUser,
  attendanceDate,
  prevSelectedUnitCodes,
  generateToken
} from '../services/user';
import { fetchFlags } from '../services/flagsmith';
import { roleReadable } from '../services/currentUser';
// import findObjectIndexByKey from '../utils/findObjectIndexByKey';
// import { courseAttendanceByCohort } from '../services/attendance';
import { setCurrentModal } from './Modals';
import { getAgreement } from './Agreements';
import {
  SET_USERS,
  SET_CURRENT_USER,
  SET_DIRECTORY_USER,
  RESET_DIRECTORY_USER,
  SET_DIRECTORY_USERS,
  GET_INSTRUCTORS,
  SET_USER,
  RESET_USER,
  RESET_USERS,
  SET_USER_PROFILE,
  SET_DIRECTORY_USER_EDITING,
  RESET_DIRECTORY_USER_EDITING
} from './types';
import { fetchUser, fetchUsers } from '../services/users';
import { fetchUserProfile } from '../services/userProfiles';
import { fetchCourses } from '../services/courses';
import { fetchLocation } from '../services/locations';
import { serializeDirectoryUsers } from '../serializers/directoryUsers';
import isPublished from '../utils/isPublished';
import * as analytics from '../utils/analytics';
import { MODAL_KEY_UPGRADE_MEMBERSHIP } from '../constants/modal';
import { showFeatureFlagNoticeModal } from './FeatureFlags';
import { NOTICE_USERS_READONLY_BODY, NOTICE_USERS_READONLY_TITLE } from '../constants/flagsmith';
import { getDirectoryUserProfile } from './UserProfiles';

export const getUserProfile = (userProfileId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchUserProfile(userProfileId)
        .then((userProfile) => {
          dispatch({ type: SET_USER_PROFILE, userProfile });
          resolve(userProfile);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };
};

// TODO move to membership actions file
export const upgradeMembershipModal = ({
  title = 'Upgrade Membership',
  content,
  purchaseCourse,
  price,
  membership,
  starterActivateUrl
}) => {
  return (dispatch) => {
    dispatch(
      setCurrentModal({
        key: MODAL_KEY_UPGRADE_MEMBERSHIP,
        data: {
          title,
          membership,
          content,
          purchaseCourse,
          price,
          starterActivateUrl
        }
      })
    );
  };
};

export const setUser = (user) => {
  return (dispatch) => {
    dispatch({
      type: SET_USER,
      user
    });
  };
};

export const resetUser = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_USER
    });
  };
};

// NOTE: Function is not in use
// TODO: Remove?
export const getUsers = async (params) => {
  return async (dispatch, getState) => {
    const { organization } = getState();
    const orgId = organization?.id || '';

    try {
      const response = await fetchUsers({ ...params, orgId });
      dispatch({
        type: SET_USERS,
        users: response?.items
      });
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  };
};

export const resetUsers = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_USERS
    });
  };
};

// NOTE: Function is not in use
// TODO: Remove?
export const getInstructors = async ({ orgId }) => {
  return async (dispatch) => {
    const config = {
      roles: ['teacher'], // TODO user roles (check against classId)
      orgId
    };

    try {
      const response = await fetchUsers(config);

      dispatch({
        type: GET_INSTRUCTORS,
        instructors: response?.items
      });

      return response?.items;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  };
};

export const getDirectoryUsers = async ({
  orgId,
  orgType,
  locationId,
  searchValue,
  roles
}) => {
  return async (dispatch, getState) => {
    const { locationsHash } = getState();
    const config = {
      excludeTestUsers: true,
      excludeInactiveUsers: true,
      orgId,
      orgType,
      locationId,
      searchValue,
      roles,
      select: [
        'id',
        'role',
        'name',
        'title',
        'locationId',
        'slackUserId',
        'email',
        'profilePicture',
        'groupIds',
        'phone',
        'phoneExt'
      ]
    };
    try {
      const response = await fetchUsers(config);

      dispatch({
        type: SET_DIRECTORY_USERS,
        directoryUsers: serializeDirectoryUsers({
          directoryUsers: response?.items,
          locationsHash
        })
      });

      return response?.items;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  };
};

export const getDirectoryUser = ({ orgId, userId }) => {
  let error = 'Something went wrong. Please try again.';

  return (dispatch) => {
    return new Promise((resolve, reject) => {
      if (!orgId) {
        error = 'orgId required!';
        console.error(error);
        reject(new Error(error));
        return false;
      }

      if (!userId) {
        error = 'userId required!';
        console.error(error);
        reject(new Error(error));
        return false;
      }

      fetchUser({ userId }).then((directoryUser) => {
        if (directoryUser?.orgId !== orgId) {
          error = 'User is not part of this organization.';
          console.error(error);
          dispatch({
            type: SET_DIRECTORY_USER,
            directoryUser: null
          });
          resolve({
            error,
            directoryUser: null
          });

          return false;
        }

        if (directoryUser?.id) {
          dispatch({
            type: SET_DIRECTORY_USER,
            directoryUser
          });

          if (directoryUser?.userProfileId) {
            dispatch(getDirectoryUserProfile(directoryUser.userProfileId));
          }

          resolve({ directoryUser });
        } else {
          error = 'No directory user found';
          console.error(error);
          reject(new Error(error));
        }
      });
    });
  };
};

export const setDirectoryUserEditing = (directoryUserEditing) => {
  return (dispatch) => {
    dispatch({
      type: SET_DIRECTORY_USER_EDITING,
      directoryUserEditing
    });
  };
};

export const resetDirectoryUserEditing = () => {
  return (dispatch) => {
    dispatch({ type: RESET_DIRECTORY_USER_EDITING });
  };
};

export const resetDirectoryUser = () => {
  return (dispatch) => {
    dispatch({ type: RESET_DIRECTORY_USER });
  };
};

export const getCurrentUser = ({ userId, select }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchUser({ userId, select }).then((response) => {
        if (response) {
          dispatch(setCurrentUser(response)).then(() => {
            resolve(response);
          });
        } else {
          console.error('Current user missing.');
          reject(new Error('Current user missing.'));
        }
      });
    });
  };
};

export const setCurrentUser = (currentUser) => {
  return (dispatch) => {
    const { locationId, courseIds, orgId } = currentUser;
    const agreementId = pathOr(false, ['agreementId'], currentUser);
    const userProfileId = pathOr(false, ['userProfileId'], currentUser);

    if (agreementId) {
      dispatch(getAgreement({ agreementId }));
    }

    if (userProfileId) {
      dispatch(getUserProfile(userProfileId));
    }

    return new Promise((resolve, reject) => {
      fetchLocation({ locationId })
        .then((userLocation) => {
          const config = {
            orgId,
            include: 2, // TODO course flatten (this is needed so eUnitTracking and other references are available)
            ...(courseIds ? { courseIds } : {})
          };

          analytics.setUserProperties({
            locationId: userLocation?.id,
            locationName: userLocation?.name
          });

          fetchCourses(config)
            .then((userCourses) => {
              const dataToSave = {
                currentUser,
                userLocation,
                userCourses: courseIds ? userCourses : null
              };

              dispatch({
                type: SET_CURRENT_USER,
                ...dataToSave
              });

              resolve(dataToSave);
            })
            .catch((error) => {
              // fetchCourses Error
              console.error(error.message);
              reject(error);
            });
        })
        .catch((error) => {
          // fetchLocation Error
          console.error(error.message);
          reject(error);
        });
    });
  };
};

export const addClassToUserClasses = ({
  userId,
  classId,
  cohortId,
  eUnitIds,
  extRegClassId,
  completedClassIdsToRemove,
  completedTopicIdsToRemove
}) => {
  return (dispatch) => {
    if (!classId || !userId) return null;

    return new Promise((resolve, reject) => {
      const data = {
        classId,
        completedClassIdsToRemove,
        completedTopicIdsToRemove
      };

      if (eUnitIds) {
        data.eUnitIds = eUnitIds;
      }

      if (cohortId) {
        data.cohortId = cohortId;
      }

      if (extRegClassId) {
        data.extRegClassId = extRegClassId;
      }

      updateUser(data, userId).then(() => {
        // TODO optimistic UI update (redux)
        fetchUser({ userId }).then((response) => {
          if (response) {
            dispatch(setCurrentUser(response)).then(() => {
              resolve({ userId, classId }); // TODO why not resolve response
            });
          } else {
            console.error('Current user missing.');
            reject(new Error('Current user missing.'));
          }
        });
        // /TODO
      });
    });
  };
};

function checkEUnitTracking({ eUnitTracking, data }) {
  if (isPublished(eUnitTracking)) {
    const {
      fields: {
        // TODO cohort flatten (eUnitTracking)
        code,
        semesterCode
      }
    } = eUnitTracking;

    if (code) {
      data.eUnitCode = code;
    }

    if (semesterCode) {
      data.semesterCode = semesterCode;
    }
  }

  return data;
}

function checkTopicSessionsSync({ topics, eUnitTracking, data }) {
  // Sessions Topic Sync
  if (isPublished(eUnitTracking)) {
    const {
      fields: {
        // TODO cohort flatten (eUnitTracking)
        sessionsTopicSync,
        currentSession,
        sessions,
        attendancePerSession
      }
    } = eUnitTracking;

    data.sessions = sessions;
    data.recordsPerSession = attendancePerSession;

    if (sessionsTopicSync && topics && topics.length) {
      data.sessions = topics.length;

      if (currentSession) {
        const activeTopic = topics[currentSession - 1];

        if (isPublished(activeTopic)) {
          // Topic exists and has content
          const {
            id: topicId, title, category, level
          } = activeTopic;

          data.sessionTopic = { id: topicId, title };

          if (category) data.sessionTopic.category = category.join(',');

          if (level) data.sessionTopic.level = level.join(',');
        }
      }
    }
  }

  return data;
}

function checkSessionCredits({
  integration, eUnitTracking, eUnits, data
}) {
  let sessionCredits = integration && integration.sessionCredits ? integration.sessionCredits : 0;

  if (isPublished(eUnitTracking)) {
    const {
      fields: {
        // TODO cohort flatten (eUnitTracking)
        integration: trackingIntegration
      }
    } = eUnitTracking;

    if (trackingIntegration && trackingIntegration.sessionCredits) {
      sessionCredits = trackingIntegration.sessionCredits;
    }
  }

  if (eUnits && eUnits.length && data.eUnitCodes) {
    // Class has Education Units
    let unitCredits = [];

    eUnits.forEach((unit) => {
      if (isPublished(unit)) {
        const {
          fields: {
            // TODO cohort flatten (eUnits)
            integration: eUnitIntegration,
            code
          }
        } = unit;

        if (eUnitIntegration && eUnitIntegration.sessionCredits) {
          // Unit has integration
          if (data.eUnitCodes.includes(code)) {
            // Unit was selected
            unitCredits = [...unitCredits, ...eUnitIntegration.sessionCredits];
          }
        }
      }
    });

    if (unitCredits.length) {
      sessionCredits = unitCredits;
    }
  }

  data.sessionCredits = sessionCredits;

  return data;
}

export const takeSessionAttendance = ({
  eUnitCodes,
  cohortId,
  cohortTitle
}) => {
  return (dispatch, getState) => {
    const {
      currentUser,
      currentClass: course,
      courseCohorts, // TODO course flatten (courseCohortIds)
      organization,
      topics
    } = getState();
    const { type: orgType } = organization;
    const { id: userId, role, attendance } = currentUser;
    const { id: classId, title: classTitle, integration } = course;

    // COHORTS
    // TODO should this use the activeCohort() function?
    const activeCohort = courseCohorts.find((c) => c.id === cohortId);
    if (!activeCohort) return null;

    const {
      eUnitTracking, // TODO cohort flatten (pull eUnitTracking by cohortId or migrate to cohort)
      eUnits // TODO cohort flatten (pull eUnits by cohortId or migrate to cohort)
    } = activeCohort;

    if (!isPublished(eUnitTracking)) return null;

    const {
      fields: {
        // TODO cohort flatten
        currentSession
      }
    } = eUnitTracking;

    return new Promise((resolve, reject) => {
      const currentDateTimestamp = attendanceDate({ utcHours: false }); // TODO is false needed here?
      let data = {
        class: {
          id: classId,
          title: classTitle
        },
        cohort: {
          id: cohortId,
          title: cohortTitle
        },
        date: currentDateTimestamp,
        recordTaker: roleReadable({ role, orgType }),
        eUnitCodes:
          eUnitCodes
          || prevSelectedUnitCodes({
            classId,
            cohortId,
            eUnits,
            attendance
          })
      };

      data = checkEUnitTracking({ eUnitTracking, data });

      data = checkTopicSessionsSync({ topics, eUnitTracking, data });

      data = checkSessionCredits({
        integration,
        eUnitTracking,
        eUnits,
        data
      });

      const attendanceObj = !attendance ? {} : { ...attendance };

      if (!attendanceObj[classId]) {
        attendanceObj[classId] = [];
      }

      // Education Unit Tracking
      // Student Attendance Tracking
      // const cohortAttendance = courseAttendanceByCohort({ // TODO is this needed
      //   courseAttendance: attendanceObj[classId],
      //   cohortId
      // });

      let sessionIndex = -1;

      // TODO refactor put in service
      // const sessionIndex = findObjectIndexByKey(cohortAttendance, 'session', currentSession);
      attendanceObj[classId].forEach((entry, entryIndex) => {
        if (entry.cohort && entry.cohort.id === cohortId) {
          if (entry.session === currentSession) {
            sessionIndex = entryIndex;
          }
        }
      });

      if (sessionIndex === -1) {
        // students first time checking into class
        data.records = [currentDateTimestamp];
        data.session = currentSession;

        attendanceObj[classId].push(data);
      } else {
        // 2nd or more times student checking into class
        attendanceObj[classId][sessionIndex].records.push(currentDateTimestamp);
        attendanceObj[classId][sessionIndex].session = currentSession;

        if (data.semesterCode) {
          attendanceObj[classId][sessionIndex].semesterCode = data.semesterCode;
        }

        if (data.eUnitCode) {
          attendanceObj[classId][sessionIndex].eUnitCode = data.eUnitCode;
        }

        if (data.eUnitCodes) {
          attendanceObj[classId][sessionIndex].eUnitCodes = data.eUnitCodes;
        }

        if (data.sessionCredits) {
          attendanceObj[classId][sessionIndex].sessionCredits = data.sessionCredits;
        }
      }

      updateUser({
        attendance: attendanceObj
      }, userId)
        .then(() => {
          // TODO optimistic UI update (redux)
          fetchUser({ userId }).then((response) => {
            if (response) {
              dispatch(setCurrentUser(response)).then(() => {
                resolve(response);
              });
            } else {
              console.error('Current user missing.');
              reject(new Error('Current user missing.'));
            }
          });
          // /TODO
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };
};

export const getUserByUniqueCertificateId = async ({ uniqueCertificateId, orgId }) => {
  return async (dispatch) => {
    try {
      const config = {
        uniqueCertificateId,
        limit: 1
      };

      if (orgId) {
        config.orgId = orgId;
      }

      const response = await fetchUsers(config);
      const items = response?.items;
      const currentUser = Array.isArray(items) && items[0] !== undefined ? items[0] : null;

      if (currentUser?.id) {
        dispatch({
          type: SET_CURRENT_USER,
          currentUser
        });

        return currentUser;
      }

      console.error('No user found, try again.');
      throw new Error('No user found, try again.');
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  };
};

export const generateCompletionCertificate = () => {
  return (dispatch, getState) => {
    return new Promise((resolve) => {
      const { currentClass: course, organization, currentUser } = getState();
      const { id: orgId } = organization;
      const { id: classId } = course;
      const { id: userId } = currentUser;

      if (course && course.completionCertificate) {
        const uniqueCertificateIdSpec = pathOr(
          false,
          ['integration', 'uniqueCertificateIdSpec'],
          course
        );

        if (uniqueCertificateIdSpec) {
          const { prefix, charLength, oneTimeUse } = uniqueCertificateIdSpec;
          // Generate Token
          // .prefix (string)
          // .charType (string)
          // .charLength (number)
          // .oneTimeUse (bool)
          generateToken({ prefix, charLength, orgId }).then(
            (uniqueCertificateId) => {
              const dataToSave = {
                orgId,
                classId,
                issueDate: new Date()
              };

              if (oneTimeUse !== undefined) {
                dataToSave.oneTimeUse = oneTimeUse;
              }

              const uniqueCertificateData = {
                [uniqueCertificateId]: dataToSave
              };

              updateUser({
                uniqueCertificateId,
                uniqueCertificateData
              }, userId).then(() => {
                // TODO Email user certificate URL
                // `https://app.turbinelms.com/completion-certificate/${uniqueCertificateId}`
                dispatch(getCurrentUser({ userId })).then(() => {
                  setTimeout(() => {
                    resolve({
                      uniqueCertificateId,
                      uniqueCertificateData
                    });
                  }, 1000);
                });
              });
            }
          );
        }
      }
    });
  };
};

export const handleEditUserDetails = ({
  orgId,
  userId,
  activeTab = 'profile'
}) => {
  return (dispatch) => {
    fetchFlags().then((fetchedFlags) => {
      if (fetchedFlags?.users_readonly?.enabled && fetchedFlags?.users_readonly?.value) {
        dispatch(showFeatureFlagNoticeModal({
          modalTitle: NOTICE_USERS_READONLY_TITLE,
          modalBody: NOTICE_USERS_READONLY_BODY
          // preventClose: true
        }));
        return;
      }

      dispatch(getDirectoryUser({ orgId, userId })).then(() => {
        dispatch(setDirectoryUserEditing({ showForms: true, activeTab }));
      });
    });
  };
};

// TODO revisit this Logic
// Currently incomplete and potentially unnecessary
// export const reEnroll = ({ course }) => {
//   return (dispatch, getState) => {
//     const { currentUser, topics } = getState();
//     const { id: userId, completedCourseTopicIds } = currentUser;
//     const { id: classId } = course;
//     const topicIds = [];

//     completedCourseTopicIds.forEach((tId) => {
//       topics.forEach((topic) => {
//         if (topic.id === tId) {
//           topicIds.push(tId);
//         }
//       })
//     });

//     return new Promise((resolve, reject) => {
//       // TODO remove quizOutcomes
//       // dispatch(getQuizOutcomes({ orgId, userId, classId })).then((quizOutcomes) => {
//       //
//       updateUser({
//         reEnroll: { classId, topicIds, course, currentUser, quizOutcomes }
//       }, userId).then(() => {
//         dispatch(getCurrentUser({ userId })).then(() =>  {
//           resolve();
//         });
//       });

//     });
//   };
// };
