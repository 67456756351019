import { pathOr } from 'ramda';
import {
  SET_USER,
  RESET_USER,
  SET_BILLING_AGREEMENT,
  SET_CURRENT_PAGE,
  SET_USERS,
  SET_CURRENT_USER,
  // COURSES
  SET_COURSES,
  RESET_COURSES,
  // COHORTS
  SET_COHORTS,
  RESET_COHORTS,
  // ENROLL
  SET_ENROLL,
  RESET_ENROLL,
  // MISC
  SET_CURRENT_CLASS,
  SET_CURRENT_CLASS_CHALLENGES,
  RESET_CURRENT_CLASS_CHALLENGES,
  SET_CURRENT_CLASS_ANSWERS_SUCCESS,
  RESET_CURRENT_CLASS_ANSWERS,
  RESET_CURRENT_CLASS,
  SET_CLASS_CERTIFICATE,
  RESET_CLASS_CERTIFICATE,
  // GROUPS
  SET_GROUPS,
  RESET_GROUPS,
  //
  SET_DIRECTORY_USER,
  RESET_DIRECTORY_USER,
  SET_DIRECTORY_USERS,
  GET_INSTRUCTORS,
  // ORG
  SET_ORG,
  RESET_ORG,
  // LOCATIONS
  SET_LOCATIONS,
  RESET_LOCATIONS,
  SET_CURRENT_ENTRY,
  // QUIZZES
  SET_QUIZ_OUTCOMES,
  RESET_QUIZ_OUTCOMES,
  SET_CURRENT_QUIZ,
  // QUIZ content flow
  SET_CURRENT_CLASS_QUIZZES,
  SET_CURRENT_CLASS_QUIZ_OUTCOMES,
  SET_CURRENT_TOPIC_QUIZ_OUTCOMES,
  RESET_CURRENT_CLASS_QUIZZES,
  RESET_CURRENT_CLASS_QUIZ_OUTCOMES,
  RESET_CURRENT_TOPIC_QUIZ_OUTCOMES,
  // end QUIZZES
  RESET_CURRENT_QUIZ,
  RESET_CURRENT_ENTRY,
  SET_CURRENT_ANSWER,
  RESET_CURRENT_ANSWER,
  SET_CURRENT_ANSWERS,
  SET_CURRENT_TOPIC_ANSWERS_SUCCESS,
  RESET_CURRENT_TOPIC_ANSWERS,
  SET_CURRENT_TOPIC_SUCCESS,
  RESET_CURRENT_TOPIC,
  SET_LOADING,
  SET_TOASTS,
  LOGOUT,
  // MODALS
  SET_CURRENT_MODAL,
  RESET_CURRENT_MODAL,
  // Manage Membership
  SET_MANAGE_MEMBERSHIP,
  RESET_MANAGE_MEMBERSHIP,
  SET_USER_PROFILE,
  RESET_USER_PROFILE,
  SET_DIRECTORY_USER_PROFILE,
  SET_DIRECTORY_USER_EDITING,
  RESET_DIRECTORY_USER_EDITING,
  RESET_DIRECTORY_USER_PROFILE,
  SET_USER_WEATHER_CURRENT,
  RESET_USER_WEATHER_CURRENT,
  SET_WIDGETS,
  RESET_WIDGETS,
  SET_APPRENTICESHIP,
  RESET_APPRENTICESHIP,
  SET_PROJECTS,
  RESET_PROJECTS,
  SET_APPRENTICESHIPS,
  SET_NOTIFICATIONS_ORG,
  RESET_NOTIFICATIONS_ORG,
  SET_NOTIFICATIONS_COURSE,
  RESET_NOTIFICATIONS_COURSE,
  SET_NOTIFICATIONS_ORG_PINNED,
  SET_NOTIFICATIONS_COURSE_PINNED,
  RESET_NOTIFICATIONS_COURSE_PINNED,
  RESET_NOTIFICATIONS_ORG_PINNED
} from '../actions/types';
import {
  classPreReqs,
  topicPreReqs,
  classChallengesQuizzes
} from '../services/courses';
import { generateLocationsHash } from '../services/locations';
import { membershipReadable } from '../services/currentUser';

const initialState = {
  organization: null,
  locations: null,
  locationsHash: {},
  loading: false,
  membership: null, // TODO is this needed?
  membershipReadable: null,
  currentItem: '',
  username: '',
  items: [],
  user: null,
  userProfile: null,
  users: null,
  // DIRECTORY
  directoryUser: null,
  directoryUserFetched: false,
  directoryUserEditing: {
    showForms: false,
    activeTab: 'profile'
  },
  directoryUserProfile: null,
  directoryUsers: null,
  // /DIRECTORY
  currentUser: null,
  topics: [],
  material: [],
  classCertificate: null,
  currentClass: null,
  currentClassRoute: null,
  currentClassChallenges: null,
  currentPage: null,
  // QUIZZES
  currentQuiz: null,
  currentClassQuizzes: null,
  quizOutcomes: {
    list: null,
    pagination: null,
    fetched: false
  },
  currentClassQuizOutcomes: {
    list: null,
    pagination: null,
    fetched: false
  },
  currentTopicQuizOutcomes: {
    list: null,
    pagination: null,
    fetched: false
  },
  // end QUIZZES
  currentEntry: null,
  currentTopic: null,
  currentTopicAnswers: null,
  classPreReqs: {},
  topicPreReqs: {},
  currentAnswer: null,
  currentAnswers: null,
  currentAnswersPagination: null,
  currentClassAnswers: [],
  // leaderboard: null,
  manageMembership: null,
  toasts: [],
  // MODALS
  currentModal: {
    visible: false,
    key: null
  },
  userWeatherLocation: null,
  userWeatherCurrent: null,
  widgets: null,
  widgetsFetched: false,
  projectsList: null,
  projectsFetched: false,
  apprenticeship: null,
  apprenticeshipFetched: false,
  apprenticeships: null,
  apprenticeshipsFetched: false,
  courses: null,
  coursesFetched: false,
  // Notifications
  notificationsOrgList: null,
  notificationsOrgListFetched: false,
  notificationsCourseList: null,
  notificationsCourseListFetched: false,
  pinnedOrgNotificationsList: null,
  pinnedOrgNotificationsFetched: true,
  pinnedCourseNotificationsList: null,
  pinnedCourseNotificationsFetched: true
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.loading
      };
    case SET_BILLING_AGREEMENT:
      return {
        ...state,
        billingAgreement: action.billingAgreement
      };
    case SET_COURSES: {
      const challengesAndQuizzes = classChallengesQuizzes({
        courses: action.courses
      });
      const classChallenges = pathOr(
        null,
        ['classes', 'challenges'],
        challengesAndQuizzes
      ); // TODO change to courses
      // const classQuizes = pathOr(null, ['classes', 'quizes'], challengesAndQuizzes); // TODO change to quizzes
      const topicChallenges = pathOr(
        null,
        ['topics', 'challenges'],
        challengesAndQuizzes
      );
      // const topicQuizes = pathOr(null, ['topics', 'quizes'], challengesAndQuizzes); // TODO change to quizzes

      return {
        ...state,
        courses: action.courses,
        coursesFetched: true,
        classPreReqs: classPreReqs({ courses: action.courses }),
        topicPreReqs: topicPreReqs({ courses: action.courses }),
        classChallenges,
        // classQuizes, // TODO quizzes
        topicChallenges
        // topicQuizes // TODO quizzes
      };
    }
    case SET_USER_PROFILE:
      return {
        ...state,
        userProfile: action.userProfile
      };
    case RESET_USER_PROFILE:
      return {
        ...state,
        userProfile: initialState.userProfile
      };
    case RESET_COURSES:
      return {
        ...state,
        courses: initialState.courses,
        coursesFetched: initialState.coursesFetched
      };
    case SET_COHORTS:
      return {
        ...state,
        ...(action.courseCohorts
          ? { courseCohorts: action.courseCohorts }
          : {}),
        ...(action.userCohorts ? { userCohorts: action.userCohorts } : {})
      };
    case RESET_COHORTS:
      return {
        ...state,
        courseCohorts: null
      };
    case SET_ENROLL: {
      const { type, ...remainingData } = action.enroll;

      return {
        ...state,
        enroll: {
          ...state.enroll,
          ...remainingData
        }
      };
    }
    case RESET_ENROLL:
      return {
        ...state,
        enroll: null
      };
    case SET_GROUPS:
      return {
        ...state,
        groups: action.groups,
        groupIds: action.groupIds
      };
    case RESET_GROUPS:
      return {
        ...state,
        groups: null,
        groupIds: null
      };
    case SET_QUIZ_OUTCOMES:
      return {
        ...state,
        quizOutcomes: {
          ...state.quizOutcomes,
          list: action?.list,
          pagination: action?.pagination,
          fetched: true
        }
      };
    case RESET_QUIZ_OUTCOMES:
      return {
        ...state,
        quizOutcomes: initialState.quizOutcomes
      };
    case SET_CURRENT_CLASS: {
      const {
        currentClass, topics, courseExperts, currentClassRoute
      } = action;

      return {
        ...state,
        currentClass,
        currentClassRoute,
        topics,
        courseExperts
      };
    }
    case RESET_CURRENT_CLASS: {
      return {
        ...state,
        currentClass: null,
        currentClassRoute: null,
        courseExperts: null,
        courseCohorts: null,
        topics: null,
        currentEntry: null
      };
    }
    case SET_CURRENT_CLASS_CHALLENGES:
      return {
        ...state,
        currentClassChallenges: action.currentClassChallenges
      };
    case RESET_CURRENT_CLASS_CHALLENGES:
      return {
        ...state,
        currentClassChallenges: null
      };
    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.currentPage
      };
    case SET_CURRENT_ENTRY:
      return {
        ...state,
        currentEntry: action.currentEntry
      };
    case RESET_CURRENT_ENTRY:
      return {
        ...state,
        currentEntry: null
      };
    case SET_CURRENT_QUIZ:
      return {
        ...state,
        currentQuiz: action.currentQuiz
      };
    case SET_CURRENT_CLASS_QUIZZES:
      return {
        ...state,
        currentClassQuizzes: action.currentClassQuizzes
      };
    case RESET_CURRENT_CLASS_QUIZZES:
      return {
        ...state,
        currentClassQuizzes: null
      };
    case SET_CURRENT_CLASS_QUIZ_OUTCOMES:
      return {
        ...state,
        currentClassQuizOutcomes: {
          ...state.currentClassQuizOutcomes,
          list: action.list,
          pagination: action.pagination,
          fetched: true
        }
      };
    case RESET_CURRENT_CLASS_QUIZ_OUTCOMES:
      return {
        ...state,
        currentClassQuizOutcomes: initialState.currentClassQuizOutcomes
      };
    case SET_CURRENT_TOPIC_QUIZ_OUTCOMES:
      return {
        ...state,
        currentTopicQuizOutcomes: {
          ...state.currentTopicQuizOutcomes,
          list: action.list,
          pagination: action.pagination,
          fetched: true
        }
      };
    case RESET_CURRENT_TOPIC_QUIZ_OUTCOMES:
      return {
        ...state,
        currentTopicQuizOutcomes: initialState.currentTopicQuizOutcomes
      };
    case RESET_CURRENT_QUIZ:
      return {
        ...state,
        currentQuiz: null
      };
    case SET_CURRENT_TOPIC_SUCCESS:
      return {
        ...state,
        currentTopic: action.currentTopic
      };
    case RESET_CURRENT_TOPIC:
      return {
        ...state,
        currentTopic: null
      };
    case SET_CURRENT_TOPIC_ANSWERS_SUCCESS:
      return {
        ...state,
        currentTopicAnswers: action.currentTopicAnswers
      };
    case RESET_CURRENT_TOPIC_ANSWERS:
      return {
        ...state,
        currentTopicAnswers: null
      };
    case SET_USER:
      return {
        ...state,
        user: action.user
      };
    case RESET_USER:
      return {
        ...state,
        user: null,
        currentUser: null
      };
    case SET_CURRENT_USER: {
      const newState = {
        ...state,
        currentUser: action.currentUser || null,
        ...(action.userLocation ? { userLocation: action.userLocation } : {}),
        ...(action.userCourses ? { userCourses: action.userCourses } : {})
      };

      if (action.currentUser?.id) {
        newState.currentUser.membershipReadable = action?.currentUser
          ?.membership
          ? membershipReadable(action?.currentUser?.membership)
          : null;
      }

      return newState;
    }
    case SET_TOASTS:
      return {
        ...state,
        toasts: action.toasts
      };
    case SET_MANAGE_MEMBERSHIP:
      return {
        ...state,
        manageMembership: action.manageMembership
      };
    case RESET_MANAGE_MEMBERSHIP:
      return {
        ...state,
        manageMembership: null
      };
    case SET_CURRENT_ANSWER:
      return {
        ...state,
        currentAnswer: action.currentAnswer
      };
    case RESET_CURRENT_ANSWER:
      return {
        ...state,
        currentAnswer: null
      };
    case SET_CURRENT_ANSWERS:
      return {
        ...state,
        currentAnswers: action.currentAnswers,
        currentAnswersPagination: action.currentAnswersPagination || null
        // leaderboard: action.leaderboard || null,
      };
    case SET_CURRENT_CLASS_ANSWERS_SUCCESS:
      return {
        ...state,
        currentClassAnswers: action.currentClassAnswers
      };
    case RESET_CURRENT_CLASS_ANSWERS:
      return {
        ...state,
        currentClassAnswers: null
      };
    case SET_CLASS_CERTIFICATE:
      return {
        ...state,
        classCertificate: action.classCertificate
      };
    case RESET_CLASS_CERTIFICATE:
      return {
        ...state,
        classCertificate: null
      };
    case SET_ORG:
      return {
        ...state,
        organization: action.organization,
        courseGroupIds: action.courseGroupIds
      };
    case RESET_ORG:
      return {
        ...state,
        organization: initialState.organization,
        locations: initialState.locations,
        locationsHash: initialState.locationsHash,
        courseGroupIds: initialState.courseGroupIds
      };
    case SET_LOCATIONS:
      return {
        ...state,
        locations: action.locations,
        locationsHash: generateLocationsHash(action.locations)
      };
    case RESET_LOCATIONS:
      return {
        ...state,
        locations: initialState.locations,
        locationsHash: initialState.locationsHash
      };
    case GET_INSTRUCTORS: // TODO DEPRECATE
      return {
        ...state,
        instructors: action.instructors
      };
    case SET_DIRECTORY_USER:
      return {
        ...state,
        directoryUser: action.directoryUser,
        directoryUserFetched: true,
        directoryUserEditing: initialState.directoryUserEditing
      };
    case SET_DIRECTORY_USER_EDITING:
      return {
        ...state,
        directoryUserEditing: {
          ...state.directoryUserEditing,
          ...action.directoryUserEditing
        }
      };
    case RESET_DIRECTORY_USER_EDITING:
      return {
        ...state,
        directoryUserEditing: initialState.directoryUserEditing
      };
    case RESET_DIRECTORY_USER:
      return {
        ...state,
        directoryUser: initialState.directoryUser,
        directoryUserFetched: initialState.directoryUserFetched,
        directoryUserEditing: initialState.directoryUserEditing,
        directoryUserProfile: initialState.directoryUserProfile
      };
    case RESET_DIRECTORY_USER_PROFILE: {
      return {
        ...state,
        directoryUserProfile: initialState.directoryUserProfile
      };
    }
    case SET_DIRECTORY_USER_PROFILE:
      return {
        ...state,
        directoryUserProfile: action.directoryUserProfile
      };
    case SET_DIRECTORY_USERS:
      return {
        ...state,
        directoryUsers: action.directoryUsers
      };
    case SET_USERS:
      return {
        ...state,
        users: action.users
      };
    case LOGOUT:
      return {
        ...initialState,
        // Why are these not reset to initialState values?
        organization: state.organization,
        locations: state.locations,
        locationsHash: state.locationsHash
      };
    case SET_CURRENT_MODAL:
      return {
        ...state,
        currentModal: action.currentModal
      };
    case RESET_CURRENT_MODAL:
      return {
        ...state,
        currentModal: initialState.currentModal
      };
    case SET_USER_WEATHER_CURRENT:
      return {
        ...state,
        userWeatherLocation: action.location,
        userWeatherCurrent: action.current,
        fetched: true
      };
    case RESET_USER_WEATHER_CURRENT:
      return {
        ...state,
        userWeatherLocation: initialState.userWeatherLocation,
        userWeatherCurrent: initialState.userWeatherCurrent
      };
    case SET_WIDGETS:
      return {
        ...state,
        widgets: action.list,
        widgetsFetched: true
      };
    case RESET_WIDGETS:
      return {
        ...state,
        widgets: initialState.widgets,
        widgetsFetched: initialState.widgetsFetched
      };
    case SET_PROJECTS: {
      return {
        ...state,
        projectsList: action.list,
        projectsFetched: true
      };
    }
    case RESET_PROJECTS:
      return {
        ...state,
        projectsList: initialState.projectsList,
        projectsFetched: initialState.projectsFetched
      };
    case SET_APPRENTICESHIP:
      return {
        ...state,
        apprenticeship: action.apprenticeship,
        apprenticeshipFetched: true
      };
    case RESET_APPRENTICESHIP:
      return {
        ...state,
        apprenticeship: initialState.apprenticeship,
        apprenticeshipFetched: initialState.apprenticeshipFetched
      };
    case SET_APPRENTICESHIPS:
      return {
        ...state,
        apprenticeships: action.apprenticeships,
        apprenticeshipsFetched: true
      };

    case SET_NOTIFICATIONS_ORG:
      return {
        ...state,
        notificationsOrgList: action.notificationsOrgList,
        // pagination: action.pagination,
        notificationsOrgListFetched: true
      };
    case RESET_NOTIFICATIONS_ORG:
      return {
        ...state,
        notificationsOrgList: initialState.notificationsOrgList,
        notificationsOrgListFetched: initialState.notificationsOrgListFetched
      };

    case SET_NOTIFICATIONS_COURSE:
      return {
        ...state,
        notificationsCourseList: action.notificationsCourseList,
        // pagination: action.pagination,
        notificationsCourseListFetched: true
      };
    case SET_NOTIFICATIONS_ORG_PINNED:
      return {
        ...state,
        pinnedOrgNotificationsList: action.pinned,
        pinnedOrgNotificationsFetched: true
      };
    case RESET_NOTIFICATIONS_ORG_PINNED:
      return {
        ...state,
        pinnedOrgNotificationsList: initialState.pinnedOrgNotificationsList,
        pinnedOrgNotificationsFetched: initialState.pinnedOrgNotificationsFetched
      };
    case RESET_NOTIFICATIONS_COURSE:
      return {
        ...state,
        notificationsCourseList: initialState.notificationsCourseList,
        notificationsCourseListFetched:
          initialState.notificationsCourseListFetched
      };
    case SET_NOTIFICATIONS_COURSE_PINNED:
      return {
        ...state,
        pinnedCourseNotificationsList: action.pinned,
        pinnedCourseNotificationsFetched: true
      };
    case RESET_NOTIFICATIONS_COURSE_PINNED:
      return {
        ...state,
        pinnedCourseNotificationsList: initialState.pinnedCourseNotificationsList,
        pinnedCourseNotificationsFetched: initialState.pinnedCourseNotificationsFetched
      };
    default:
      return state;
  }
};

export default reducer;
