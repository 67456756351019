import { compose, isEmpty, symmetricDifference } from 'ramda';
import { client, flattenItems } from './contentful';

export const getQuizzes = (classId) => {
  return new Promise((resolve, reject) => {
    const config = {
      content_type: 'quiz',
      'fields.classId': classId
    };

    return client.getEntries(config).then(({ items }) => {
      const quizzes = items.length ? flattenItems(items) : null;

      resolve(quizzes);
    }).catch((error) => {
      console.error(error);
      reject(error);
    });
  });
};

export const evaluateQuiz = ({ currentQuiz, elements }) => {
  const evaluation = [];
  const userAnswers = [];
  let score = 0;
  const maxScore = currentQuiz.questions.length;
  const eqValues = compose(isEmpty, symmetricDifference);

  currentQuiz.questions.forEach((question, i) => {
    const correctAnswers = question.answers.filter((q) => q.correct);
    const numCorrectAnswers = correctAnswers ? correctAnswers.length : 0;
    const userAnswer = elements[question.id];

    if (numCorrectAnswers > 1) {
      //
      // Question has multiple correct answers
      //
      // Convert nodeList to Array
      const userAnswersArray = Array.from(userAnswer);
      const correctUserAnswers = userAnswersArray.filter((ca) => ca.checked === true);
      // Map values to array
      const userMultipleAnswers = correctUserAnswers.map((a) => parseInt(a.value, 10));
      // Push user answers array to userAnswers
      if (userMultipleAnswers && userMultipleAnswers.length !== 0) {
        userAnswers.push(userMultipleAnswers);
      }

      // Multiple correct answers for this question
      if (eqValues(userMultipleAnswers, currentQuiz.rubric[i])) {
        evaluation.push(true);
        score += 1;
      } else {
        evaluation.push(false);
      }
    } else {
      //
      // Question has 1 correct answer
      //
      userAnswers.push(parseInt(userAnswer.value, 10));

      if (parseInt(userAnswer.value, 10) === currentQuiz.rubric[i]) {
        // Only one correct answer for this question
        evaluation.push(true);
        score += 1;
      } else {
        evaluation.push(false);
      }
    }
  });

  return {
    evaluation,
    userAnswers,
    score,
    maxScore
  };
};
