import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WELCOME_WAVE } from '@apprentage/constants';
import RenderMarkdown from '../../ManageContent/RenderMarkdown';
import { resetCurrentModal } from '../../../actions';
import { updateUser } from '../../../services/user';
import Modal from '../../Modal';

const Welcome = () => {
  const dispatch = useDispatch();
  // Redux
  const currentModal = useSelector((state) => state.currentModal);
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  // Organization
  const orgName = organization?.name || '';
  const enableNewEditor = true;
  const welcomeMessage = organization?.welcomeMessage || '';
  // User
  const userId = currentUser?.id || '';

  const handleUserOnBoarded = () => {
    updateUser({
      onboarded: true
    }, userId);
  };

  const handleClose = () => {
    handleUserOnBoarded();
    dispatch(resetCurrentModal());
  };

  return (
    <Modal
      visible={currentModal.visible}
      pos="middle"
      close={handleClose}
    >
      <div
        className="d-flex flex-column align-items-center justify-content-center text-center h-100"
      >
        <img src={WELCOME_WAVE} alt="Waiving Hand" width="60" />

        <h3 className="mt-2 mb-4">
          Welcome to
          <br />
          {orgName}!
        </h3>

        {welcomeMessage && (
          <div>
            <RenderMarkdown
              source={welcomeMessage}
              enableNewEditor={enableNewEditor}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default Welcome;
