import React, { Fragment, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import contentTypeIcon from '../../../services/contentTypeIcon';
import Outcome from './Outcome';

const OutcomesHeader = ({ className }) => (
  <thead className={`${className || ''} border-0`}>
    <tr>
      <th scope="col">Submitted</th>
      <th scope="col">Score</th>
      <th scope="col">Percentage</th>
      <th scope="col">Attempts</th>
      <th scope="col">Points</th>
      <th scope="col">Feedback</th>
      {/* <th scope="col" className='border-0 d-none d-md-table-cell'>Details</th> */}
    </tr>
  </thead>
);

const Outcomes = () => {
  // Redux
  const quizOutcomes = useSelector((state) => state.quizOutcomes);
  // Local State
  const [showPrevScores, setShowPrevScores] = useState(false);

  const hasQuizOutcomes = Array.isArray(quizOutcomes?.list) && quizOutcomes.list.length > 0;

  const hasPrevScores = useMemo(() => {
    let prevScores = [];

    if (hasQuizOutcomes) {
      prevScores = quizOutcomes.list[0].prevScores;
    }

    return Array.isArray(prevScores) && prevScores.length > 0;
  }, [hasQuizOutcomes, quizOutcomes?.list]);

  if (!hasQuizOutcomes) {
    return null;
  }

  return (
    <div className="CurrentQuizOutcomes-container card mb-3">
      <div className="card-header bg-light p-3 border-0">
        <h5 className="m-0">
          {contentTypeIcon({ contentType: 'quiz' })} Outcomes
        </h5>
      </div>
      <div className="card-body p-0">
        <table className="table table-striped m-0">
          <OutcomesHeader />
          <tbody>
            {quizOutcomes.list.map((outcome, i) => {
              return (
                <Fragment key={outcome.id + i}>
                  <Outcome
                    data={outcome}
                  />
                  {outcome?.prevScores && outcome.prevScores.map((prevOutcome, prevI) => {
                    return (
                      <Fragment key={outcome.id + prevI}>
                        <Outcome
                          data={prevOutcome}
                          className={showPrevScores ? '' : 'd-none'}
                          prevOutcome
                        />
                      </Fragment>
                    );
                  })}
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </div>

      {hasPrevScores && (
        <div className="card-footer d-flex justify-content-center">
          <button
            className="btn-link p-0"
            type="button"
            onClick={() => {
              setShowPrevScores(!showPrevScores);
            }}
          >
            {showPrevScores ? 'Hide' : 'View'} Previous Scores
          </button>
        </div>
      )}
    </div>
  );
};

export default Outcomes;
