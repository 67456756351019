import { toast } from 'react-toastify';
import { QUIZ_OUTCOMES_PAGINATION_LIMIT } from '../constants/api';
import { serializeQuizOutcome, serializeQuizOutcomes } from '../serializers/quizOutcomes';
import { createOutcome, fetchQuizOutcome, fetchQuizOutcomes } from '../services/quizOutcomes';
import {
  RESET_QUIZ_OUTCOME,
  RESET_QUIZ_OUTCOMES,
  SET_QUIZ_OUTCOME,
  SET_QUIZ_OUTCOMES,
  // Content Flow
  SET_CURRENT_CLASS_QUIZ_OUTCOMES,
  SET_CURRENT_TOPIC_QUIZ_OUTCOMES,
  RESET_CURRENT_CLASS_QUIZ_OUTCOMES,
  RESET_CURRENT_TOPIC_QUIZ_OUTCOMES
} from './types';
import { topicApproval } from './Assessments';

export const getQuizOutcomes = ({
  orgId,
  orgIds,
  locationId,
  quizId,
  userId,
  classId,
  limit = QUIZ_OUTCOMES_PAGINATION_LIMIT,
  page = 1
}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchQuizOutcomes({
        limit,
        page,
        quizId,
        userId,
        classId,
        locationId,
        orgId,
        orgIds
      }).then((rsp) => {
        const items = Array.isArray(rsp?.items) ? rsp?.items : [];
        const list = items.length > 0 ? serializeQuizOutcomes(items) : null;

        const data = {
          list,
          pagination: {
            limit,
            total: rsp?.total,
            page: rsp?.page || 1,
            rangeFrom: rsp?.rangeFrom,
            rangeTo: rsp?.rangeTo
          }
        };

        dispatch({
          type: SET_QUIZ_OUTCOMES,
          ...data
        });

        resolve(data);
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
  };
};

export const getQuizOutcome = (quizOutcomeId) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      fetchQuizOutcome(quizOutcomeId).then((response) => {
        const quizOutcome = response ? serializeQuizOutcome(response) : null;

        dispatch({
          type: SET_QUIZ_OUTCOME,
          quizOutcome
        });

        resolve(quizOutcome);
      });
    });
  };
};

// Content Flow
export const getCurrentClassQuizOutcomes = ({
  orgId,
  classId,
  userId,
  limit = QUIZ_OUTCOMES_PAGINATION_LIMIT
}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const config = {
        orgId,
        classId,
        limit
      };

      if (userId) {
        config.userId = userId;
      }

      fetchQuizOutcomes(config).then((rsp) => {
        const items = Array.isArray(rsp?.items) ? rsp?.items : [];
        const list = items.length > 0 ? items : null;

        dispatch({
          type: SET_CURRENT_CLASS_QUIZ_OUTCOMES,
          list,
          pagination: rsp?.pagination
        });
        resolve(list);
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
  };
};

export const getCurrentTopicQuizOutcomes = ({ orgId, topicId, userId }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const config = {
        orgId,
        topicId
      };

      if (userId) {
        config.userId = userId;
      }

      fetchQuizOutcomes(config).then((rsp) => {
        const items = Array.isArray(rsp?.items) ? rsp?.items : [];
        const list = items.length > 0 ? items : null;

        dispatch({
          type: SET_CURRENT_TOPIC_QUIZ_OUTCOMES,
          list,
          pagination: rsp?.pagination
        });
        resolve(list);
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
  };
};
// /Content Flow

export const resetCurrentClassQuizOutcomes = () => { // TODO unused
  return (dispatch) => {
    dispatch({ type: RESET_CURRENT_CLASS_QUIZ_OUTCOMES });
  };
};

export const resetCurrentTopicQuizOutcomes = () => { // TODO unused
  return (dispatch) => {
    dispatch({ type: RESET_CURRENT_TOPIC_QUIZ_OUTCOMES });
  };
};

export const resetQuizOutcomes = () => { // TODO unused & not present b4
  return (dispatch) => {
    dispatch({ type: RESET_QUIZ_OUTCOMES });
  };
};

export const resetQuizOutcome = () => { // TODO unused & not present b4
  return (dispatch) => {
    dispatch({ type: RESET_QUIZ_OUTCOME });
  };
};

// TODO these should come from services and the send dispatch to update redux

export const setQuizOutcomes = (quizOutcome) => {
  return (dispatch, getState) => {
    const { quizOutcomes } = getState();
    let updatedOutcomes = [];

    if (Array.isArray(quizOutcomes?.list) && quizOutcomes.list.length > 0) {
      updatedOutcomes = [...quizOutcomes.list];
    }

    if (quizOutcome) {
      updatedOutcomes.push(quizOutcome);
    }

    const data = {
      list: serializeQuizOutcomes(updatedOutcomes),
      pagination: quizOutcomes.pagination
    };

    return new Promise((resolve) => {
      dispatch({
        type: SET_QUIZ_OUTCOMES,
        ...data
      });

      resolve(data);
    });
  };
};

export const createQuizOutcome = (data) => {
  return (dispatch, getState) => {
    const {
      currentClass: { id: classId },
      currentTopic: { id: topicId },
      currentQuiz: { id: quizId },
      currentUser: {
        id: userId,
        locationId,
        apprenticeshipIds,
        orgId // Course can be syndicated to another Organization
      }
    } = getState();

    const dataToSave = {
      ...data,
      userId,
      orgId,
      locationId,
      classId,
      quizId,
      topicId
    };

    // Temporary solution
    // TODO Pass apprenticeship Id in data argument
    if (!dataToSave.apprenticeshipId) {
      if (apprenticeshipIds && Array.isArray(apprenticeshipIds) && apprenticeshipIds.length) {
        const [firstApprenticeshipId] = apprenticeshipIds;

        dataToSave.apprenticeshipId = firstApprenticeshipId;
      }
    }

    return new Promise((resolve) => {
      createOutcome(dataToSave).then(() => {
        toast.info('Processing...', {
          toastId: 'processingContentFlow',
          autoClose: false
        });

        // TODO pass orgIds if course.orgIds exists
        dispatch(getQuizOutcomes({ orgId, quizId, userId })).then(() => {
          // Check ASSESS ENGINE if
          // Topic and Course are complete
          dispatch(getCurrentClassQuizOutcomes({ orgId, classId, userId })).then(() => {
            dispatch(getCurrentTopicQuizOutcomes({ orgId, topicId, userId })).then(() => {
              dispatch(topicApproval({ catalyst: 'quiz' })).then(() => {
                resolve();
                toast.dismiss();
              });
            });
          });
        });
      });
    });
  };
};
