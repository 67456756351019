import formatAnswer from './formatAnswer';
import formatAgreement from './formatAgreement';
import formatChallenge from './formatChallenge';
import formatMaterial from './formatMaterial';
import formatQuiz from './formatQuiz';
import formatTopic from './formatTopic';
import formatClass from './formatClass';
import formatUser from './formatUser';

function formatData(args, type, entry) {
  switch (type) {
    case 'user':
      return formatUser(args, entry);
    case 'answer':
      return formatAnswer(args);
    case 'agreement':
      return formatAgreement(args);
    case 'material':
      return formatMaterial(args);
    case 'challenge':
      return formatChallenge(args);
    case 'quiz':
      return formatQuiz(args);
    case 'topic':
      return formatTopic(args);
    case 'class':
      return formatClass(args);
    default:
      return false;
  }
}

export default formatData;
