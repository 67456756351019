// MEMBERS
export const SET_USERS = 'SET_USERS';
export const RESET_USERS = 'RESET_USERS';

// PROJECTS
export const SET_PROJECT = 'SET_PROJECT';
export const RESET_PROJECT = 'RESET_PROJECT';
export const SET_PROJECTS = 'SET_PROJECTS';
export const RESET_PROJECTS = 'RESET_PROJECTS';

export const SET_BILLING_AGREEMENT = 'SET_BILLING_AGREEMENT';

// Current User (contentful)
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_USER_PROFILE = 'SET_USER_PROFILE';
export const RESET_USER_PROFILE = 'RESET_USER_PROFILE';
// User (firebase)
export const SET_USER = 'SET_USER';
export const RESET_USER = 'RESET_USER';

// QUIZ
export const SET_QUIZ_OUTCOME = 'SET_QUIZ_OUTCOME';
export const RESET_QUIZ_OUTCOME = 'RESET_QUIZ_OUTCOME';
export const SET_QUIZ_OUTCOMES = 'SET_QUIZ_OUTCOMES';
export const RESET_QUIZ_OUTCOMES = 'RESET_QUIZ_OUTCOMES';
export const SET_CURRENT_QUIZ = 'SET_CURRENT_QUIZ';
export const RESET_CURRENT_QUIZ = 'RESET_CURRENT_QUIZ';
// QUIZ Content Flow
export const SET_CURRENT_CLASS_QUIZZES = 'SET_CURRENT_CLASS_QUIZZES';
export const SET_CURRENT_CLASS_QUIZ_OUTCOMES = 'SET_CURRENT_CLASS_QUIZ_OUTCOMES';
export const SET_CURRENT_TOPIC_QUIZ_OUTCOMES = 'SET_CURRENT_TOPIC_QUIZ_OUTCOMES';
export const RESET_CURRENT_CLASS_QUIZZES = 'RESET_CURRENT_CLASS_QUIZZES';
export const RESET_CURRENT_CLASS_QUIZ_OUTCOMES = 'RESET_CURRENT_CLASS_QUIZ_OUTCOMES';
export const RESET_CURRENT_TOPIC_QUIZ_OUTCOMES = 'RESET_CURRENT_TOPIC_QUIZ_OUTCOMES';

// GROUPS
export const SET_GROUPS = 'SET_GROUPS';
export const RESET_GROUPS = 'RESET_GROUPS';

// COURSES
export const SET_COURSES = 'SET_COURSES';
export const RESET_COURSES = 'RESET_COURSES';

// COHORTS
export const SET_COHORTS = 'SET_COHORTS';
export const RESET_COHORTS = 'RESET_COHORTS';

// INVITATION
export const SET_ENROLL = 'SET_ENROLL';
export const RESET_ENROLL = 'RESET_ENROLL';

export const SET_CURRENT_CLASS = 'SET_CURRENT_CLASS';
export const RESET_CURRENT_CLASS = 'RESET_CURRENT_CLASS';
export const SET_CLASS_CERTIFICATE = 'SET_CLASS_CERTIFICATE';
export const RESET_CLASS_CERTIFICATE = 'RESET_CLASS_CERTIFICATE';

export const SET_CURRENT_CLASS_ANSWERS_SUCCESS = 'SET_CURRENT_CLASS_ANSWERS_SUCCESS';
export const RESET_CURRENT_CLASS_ANSWERS = 'RESET_CURRENT_CLASS_ANSWERS';

export const SET_CURRENT_TOPIC_ANSWERS_SUCCESS = 'SET_CURRENT_TOPIC_ANSWERS_SUCCESS';
export const RESET_CURRENT_TOPIC_ANSWERS = 'RESET_CURRENT_TOPIC_ANSWERS';
export const RESET_CURRENT_TOPIC = 'RESET_CURRENT_TOPIC';

export const SET_CURRENT_CLASS_CHALLENGES = 'SET_CURRENT_CLASS_CHALLENGES';
export const RESET_CURRENT_CLASS_CHALLENGES = 'RESET_CURRENT_CLASS_CHALLENGES';

export const SET_CURRENT_TOPIC_SUCCESS = 'SET_CURRENT_TOPIC_SUCCESS';

export const SET_CURRENT_ENTRY = 'SET_CURRENT_ENTRY';
export const RESET_CURRENT_ENTRY = 'RESET_CURRENT_ENTRY';

// Current Answer
export const SET_CURRENT_ANSWER = 'SET_CURRENT_ANSWER';
export const RESET_CURRENT_ANSWER = 'RESET_CURRENT_ANSWER';
export const SET_CURRENT_ANSWERS = 'SET_CURRENT_ANSWERS';

export const GET_INSTRUCTORS = 'GET_INSTRUCTORS';

// DIRECTORY
export const SET_DIRECTORY_USER = 'SET_DIRECTORY_USER';
export const RESET_DIRECTORY_USER = 'RESET_DIRECTORY_USER';

export const SET_DIRECTORY_USER_EDITING = 'SET_DIRECTORY_USER_EDITING';
export const RESET_DIRECTORY_USER_EDITING = 'RESET_DIRECTORY_USER_EDITING';

export const SET_DIRECTORY_USER_PROFILE = 'SET_DIRECTORY_USER_PROFILE';
export const RESET_DIRECTORY_USER_PROFILE = 'RESET_DIRECTORY_USER_PROFILE';

export const SET_DIRECTORY_USERS = 'SET_DIRECTORY_USERS';

// Org
export const SET_ORG = 'SET_ORG';
export const RESET_ORG = 'RESET_ORG';

// LOCATIONS
export const SET_LOCATIONS = 'SET_LOCATIONS';
export const RESET_LOCATIONS = 'RESET_LOCATIONS';

// Toasts
export const SET_TOASTS = 'SET_TOASTS';

// Session
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const SET_LOADING = 'SET_LOADING';

// Slack
// TODO refactor and remove
export const SLACK_JOIN_CHANNEL_SUCCESS = 'SLACK_JOIN_CHANNEL_SUCCESS';
export const SLACK_ALREADY_JOINED_WORKSPACE_SUCCESS = 'SLACK_ALREADY_JOINED_WORKSPACE_SUCCESS';

export const LOGOUT = 'LOGOUT';

// MODALS
export const SET_CURRENT_MODAL = 'SET_CURRENT_MODAL';
export const RESET_CURRENT_MODAL = 'RESET_CURRENT_MODAL';

// Manage Membership
export const SET_MANAGE_MEMBERSHIP = 'SET_MANAGE_MEMBERSHIP';
export const RESET_MANAGE_MEMBERSHIP = 'RESET_MANAGE_MEMBERSHIP';

// WEATHER
export const SET_USER_WEATHER_CURRENT = 'SET_USER_WEATHER_CURRENT';
export const RESET_USER_WEATHER_CURRENT = 'RESET_USER_WEATHER_CURRENT';

export const SET_LOCATIONS_WEATHER_CURRENT = 'SET_LOCATIONS_WEATHER_CURRENT';
export const RESET_LOCATIONS_WEATHER_CURRENT = 'RESET_LOCATIONS_WEATHER_CURRENT';

// WIDGETS
export const SET_WIDGETS = 'SET_WIDGETS';
export const RESET_WIDGETS = 'RESET_WIDGETS';

// APPRENTICESHIPS
export const SET_APPRENTICESHIPS = 'SET_APPRENTICESHIPS';
export const RESET_APPRENTICESHIPS = 'RESET_APPRENTICESHIPS';
export const SET_APPRENTICESHIP = 'SET_APPRENTICESHIP';
export const RESET_APPRENTICESHIP = 'RESET_APPRENTICESHIP';

// Notification (supabase)
export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const RESET_NOTIFICATION = 'RESET_NOTIFICATION';
// Notification Files (aws)
export const SET_NOTIFICATION_FILES = 'SET_NOTIFICATION_FILES';
export const RESET_NOTIFICATION_FILES = 'RESET_NOTIFICATION_FILES';
// Notifications Organization (supabase)
export const SET_NOTIFICATIONS_ORG = 'SET_NOTIFICATIONS_ORG';
export const RESET_NOTIFICATIONS_ORG = 'RESET_NOTIFICATIONS_ORG';
export const SET_NOTIFICATIONS_ORG_PINNED = 'SET_NOTIFICATIONS_ORG_PINNED';
export const RESET_NOTIFICATIONS_ORG_PINNED = 'RESET_NOTIFICATIONS_ORG_PINNED';

// Notifications Course (supabase)
export const SET_NOTIFICATIONS_COURSE = 'SET_NOTIFICATIONS_COURSE';
export const RESET_NOTIFICATIONS_COURSE = 'RESET_NOTIFICATIONS_COURSE';
export const SET_NOTIFICATIONS_COURSE_PINNED = 'SET_NOTIFICATIONS_COURSE_PINNED';
export const RESET_NOTIFICATIONS_COURSE_PINNED = 'RESET_NOTIFICATIONS_COURSE_PINNED';
