import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetCurrentQuiz,
  setCurrentClassAnswers,
  setCurrentClassChallenges,
  setCurrentClassQuizzes,
  setCurrentTopicAnswers
} from '../../../actions';
import {
  getQuizOutcomes,
  resetQuizOutcomes
} from '../../../actions/QuizOutcomes';
import { canManageContent } from '../../../services/currentUser';
import Alert from '../../Alert';
import ManageButton from '../../btns/ManageButton';
import Outcomes from './Outcomes';
import Form from './Form';
import './style.css';

const Quiz = () => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentQuiz = useSelector((state) => state.currentQuiz);
  const currentUser = useSelector((state) => state.currentUser);
  const course = useSelector((state) => state.currentClass);
  const currentTopic = useSelector((state) => state.currentTopic);
  // Organization
  const orgId = organization?.id || null;
  // Current User
  const userId = currentUser?.id || null;
  // Topic
  const topicId = currentTopic?.id || null;
  // Course
  const classId = course?.id || null;
  const courseReadOnly = course?.courseReadOnly || null;
  // Quiz
  const quizId = currentQuiz?.id || null;
  const activeQuiz = currentQuiz?.activeQuiz || null;

  useEffect(() => {
    if (topicId && userId) {
      dispatch(setCurrentTopicAnswers({ topicId, userId }));
    }
  }, [dispatch, topicId, userId]);

  useEffect(() => {
    if (classId && userId) {
      dispatch(setCurrentClassAnswers({ classId, userId }));
    }
  }, [classId, dispatch, userId]);

  useEffect(() => {
    if (classId) {
      dispatch(setCurrentClassChallenges({ classId }));
      dispatch(setCurrentClassQuizzes({ classId }));
    }
  }, [classId, dispatch]);

  useEffect(() => {
    if (currentUser?.orgId && quizId && userId) {
      dispatch(getQuizOutcomes({
        orgId: currentUser?.orgId, // Course can be syndicated to another Organization
        quizId,
        userId
      }));
    }
  }, [currentUser?.orgId, dispatch, quizId, userId]);

  useEffect(() => {
    const quizForm = document.getElementById('class-quiz');

    if (quizId && quizForm) {
      quizForm.reset();
    }
  }, [quizId]);

  useEffect(() => {
    return function cleanup() {
      dispatch(resetCurrentQuiz());
      dispatch(resetQuizOutcomes()); // TODO do we need to do this? any side effects?
    };
  }, [dispatch]);

  if (!orgId || !classId || !topicId || !quizId) {
    return null;
  }

  if (!userId && !courseReadOnly) {
    return null;
  }

  return (
    <div className="row">
      <div className="col-12">

        <Outcomes />

        {!activeQuiz && (
          <Alert
            type="light"
            icon="fas fa-users-slash"
            className="mx-3 mt-3 border-bottom"
          >
            <h6>
              <small><i className="fas fa-circle text-danger" /></small> Inactive
            </h6>
            <div>
              This Quiz is not currently accepting user submissions.
            </div>
          </Alert>
        )}

        {!activeQuiz && canManageContent({ currentUser, course }) && (
          <Alert type="light" icon="fas fa-info-circle" className="mx-3">
            <div style={{ fontSize: '1rem' }}>
              After reviewing your Quiz,
              <ManageButton
                className="btn-link text-lowercase px-1"
                classId={classId}
                topicId={topicId}
                entryId={quizId}
                contentType="quiz"
                scrollToId="quiz-activeQuiz"
                title="Quiz to make it active"
                manageType="edit"
              >
                make it active
              </ManageButton>
              to begin accepting user submissions.
            </div>
            <hr className="my-2 mx-0" />
            <div className="small font-weight-bold">
              <i className="far fa-eye-slash" /> Only Visible to Teachers & Admins
            </div>
          </Alert>
        )}

        <Form />
      </div>
    </div>
  );
};

export default Quiz;
