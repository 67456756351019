import {
  createSupabaseEntry,
  deleteSupabaseEntry,
  fetchSupabaseEntries,
  fetchSupabaseEntry,
  updateSupabaseEntry
} from './supabaseProxy';
import { QUIZ_OUTCOMES_PAGINATION_LIMIT } from '../constants/api';

const table = 'quizOutcomes';

export const fetchQuizOutcomes = async ({
  orgId,
  orgIds,
  locationId,
  quizId,
  userId,
  classId,
  topicId,
  ids,
  order = 'createdAt',
  page = 1,
  select,
  limit = QUIZ_OUTCOMES_PAGINATION_LIMIT
}) => {
  const params = {
    order,
    page,
    limit,
    orgId
  };

  if (Array.isArray(orgIds) && orgIds.length) {
    params['f.orgId[in]'] = orgIds.join(',');
  } else {
    params['f.orgId[eq]'] = orgId;
  }

  if (locationId) {
    params['f.locationId[eq]'] = locationId;
  }

  if (quizId) {
    params['f.quizId[eq]'] = quizId;
  }

  if (topicId) {
    params['f.topicId[eq]'] = topicId;
  }

  if (userId) {
    params['f.userId[eq]'] = userId;
  }

  if (classId) {
    params['f.classId[eq]'] = classId;
  }

  if (Array.isArray(select) && select.length) {
    params.select = select.join(',');
  }

  if (Array.isArray(ids) && ids.length) {
    params.ids = ids.join(',');
  }

  const response = await fetchSupabaseEntries(params, table);

  return response;
};

export const fetchQuizOutcome = async (id) => {
  const response = await fetchSupabaseEntry({
    table,
    id
  });

  return response;
};

export const updateQuizOutcome = (data, id) => {
  return new Promise((resolve, reject) => {
    updateSupabaseEntry({
      id,
      data,
      table
    }).then((response) => {
      resolve(response);
    }).catch((error) => {
      console.error(`update ${table}`, error);
      reject(error);
    });
  });
};

const createQuizOutcome = (data) => {
  return new Promise((resolve, reject) => {
    createSupabaseEntry({
      data,
      table
    }).then((response) => {
      resolve(response);
    }).catch((error) => {
      console.error(`create ${table}`, error);
      reject(error);
    });
  });
};

// TODO update pattern of pulling createQuizOutcome from services
// then using dispatch(getQuizOutcomes) after
export const createOutcome = createQuizOutcome;

export const deleteQuizOutcomes = async (ids) => {
  return new Promise((resolve, reject) => {
    if (!ids) {
      reject('ids required');
    }

    const promises = ids.map(async (id) => {
      return await deleteSupabaseEntry({
        data: { id },
        table
      });
    });

    return Promise.all(promises).then(async (responses) => {
      resolve(responses);
    }).catch((error) => {
      console.error(`delete ${table}`, error);
      reject(error);
    });
  });
};
