import { toast } from 'react-toastify';
import { updateUser } from '../services/user';
import { classAnswersEval, topicAnswersEval } from '../services/contentFlow/challenges';
import { classQuizOutcomesEval, topicQuizOutcomesEval } from '../services/contentFlow/quizzes';
import { MODAL_KEY_COURSE_COMPLETE, MODAL_KEY_TOPIC_COMPLETE } from '../constants/modal';
import { getCurrentUser } from './Users';
import { setCurrentModal } from './Modals';

// #4 (Content Flow)
export const classCompleted = (resolve) => {
  return (dispatch, getState) => {
    const { currentClass: course, currentUser } = getState();
    const userId = currentUser?.id || '';
    const classId = course?.id || '';

    updateUser({
      completedClassId: classId
    }, userId).then(() => {
      // Get current user updated data (completedCourseIds) so UI unlocks the next class
      dispatch(getCurrentUser({ userId })).then(() => {
        // Show Course completion modal
        dispatch(setCurrentModal({ key: MODAL_KEY_COURSE_COMPLETE })).then(() => {
          resolve();
        });
      });
    });
  };
};

// #3 (Content Flow)
export const classApproval = ({ catalyst }, resolve) => {
  return (dispatch, getState) => {
    const {
      currentClassQuizzes,
      currentClassChallenges,
      currentClassAnswers,
      currentClassQuizOutcomes
    } = getState();

    const classChallengesCompleted = classAnswersEval({
      catalyst,
      challenges: currentClassChallenges,
      answers: currentClassAnswers
    });
    const classQuizzesCompleted = classQuizOutcomesEval({
      quizzes: currentClassQuizzes,
      quizOutcomes: currentClassQuizOutcomes?.list
    });

    // console.log('class', { classChallengesCompleted, classQuizzesCompleted });

    if (classChallengesCompleted && classQuizzesCompleted) {
      toast.dismiss();
      dispatch(classCompleted(resolve));
      toast.info('Analyzing data...');
    } else {
      // Show Topic Complete Modal
      dispatch(setCurrentModal({ key: MODAL_KEY_TOPIC_COMPLETE })).then(() => {
        resolve();
      });
    }
  };
};

// #2 (Content Flow)
export const topicCompleted = ({ catalyst }, resolve) => {
  return (dispatch, getState) => {
    const { currentTopic, currentUser } = getState();
    const userId = currentUser?.id || '';
    const topicId = currentTopic?.id || '';

    updateUser({
      completedTopicId: topicId
    }, userId).then(() => {
      // Topic Completed, check if class is Completed
      dispatch(getCurrentUser({ userId })).then(() => {
        // Get current user updated data (completedCourseTopicIds) so UI unlocks the next topic
        dispatch(classApproval({ catalyst }, resolve));
      });
    });
  };
};

// #1 (Content Flow)
export const topicApproval = ({ catalyst }) => {
  return (dispatch, getState) => {
    const {
      currentTopic,
      currentTopicAnswers,
      currentTopicQuizOutcomes
    } = getState();

    const {
      quizes: topicQuizzes, // TODO topic flatten (quizIds, rm quizzes key)
      challenges: topicChallenges
    } = currentTopic;

    return new Promise((resolve) => {
      const topicChallengesCompleted = topicAnswersEval({
        catalyst,
        challenges: topicChallenges,
        answers: currentTopicAnswers
      });
      const topicQuizzesCompleted = topicQuizOutcomesEval({
        quizzes: topicQuizzes,
        quizOutcomes: currentTopicQuizOutcomes?.list
      });

      // console.log('topic', { topicChallengesCompleted, topicQuizzesCompleted });

      if (topicChallengesCompleted && topicQuizzesCompleted) {
        dispatch(topicCompleted({ catalyst }, resolve));
      } else {
        resolve();
      }
    });
  };
};
