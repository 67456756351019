import { client, flattenItems } from '../services/contentful';
import serializeQuiz from '../serializers/quiz';
import {
  SET_CURRENT_QUIZ,
  RESET_CURRENT_QUIZ,
  // Content Flow
  SET_CURRENT_CLASS_QUIZZES,
  RESET_CURRENT_CLASS_QUIZZES
} from './types';
// Content Flow

// TODO - implement similar function like "setCurrentAnswers"
// to create <Progress /> like challenges
// setCurrentUserQuizOutcomes = () => {...}

export const setCurrentClassQuizzes = ({ classId }) => {
  return (dispatch) => {
    const config = {
      content_type: 'quiz',
      'fields.classId': classId
    };

    return client.getEntries(config).then(({ items }) => {
      const currentClassQuizzes = items.length ? flattenItems(items) : null;

      dispatch({
        type: SET_CURRENT_CLASS_QUIZZES,
        currentClassQuizzes
      });
    }).catch((error) => {
      console.error(error);
    });
  };
};

// end Content Flow

export const setCurrentQuiz = (currentEntry) => {
  return (dispatch) => {
    dispatch({
      type: SET_CURRENT_QUIZ,
      currentQuiz: serializeQuiz(currentEntry)
    });
  };
};

export const resetCurrentClassQuizzes = () => {
  return (dispatch) => {
    dispatch({ type: RESET_CURRENT_CLASS_QUIZZES });
  };
};

export const resetCurrentQuiz = () => {
  return (dispatch) => {
    dispatch({ type: RESET_CURRENT_QUIZ });
  };
};
