import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaClipboardList } from 'react-icons/fa';
import { setCurrentAnswers } from '../../../actions/Answers';
import AssessmentsList from '../../AssessmentsList';

const CurrentUserAssessments = () => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const currentAnswers = useSelector((state) => state.currentAnswers);
  // Current User
  const userId = currentUser?.id || null;
  // Organization
  const orgId = organization?.id || null;

  const numAnswers = useMemo(() => {
    if (Array.isArray(currentAnswers)) {
      return currentAnswers.length;
    }

    return 0;
  }, [currentAnswers]);

  useEffect(() => {
    if (orgId && userId) {
      dispatch(setCurrentAnswers({
        status: 'all',
        userId,
        orgId
      }));
    }
  }, [userId, orgId, dispatch]);

  return (
    <div className="card h-100">
      <div className="card-header bg-light p-3">
        <div className="h5 m-0">
          <span className='d-flex align-items-center'>
            <FaClipboardList />
            <span className='ml-2'>
              My Submissions
            </span>
            <span className='badge bg-white border ml-2'>
              {numAnswers}
            </span>
          </span>
        </div>
      </div>
      <div className="card-content overflow-hidden">
        <AssessmentsList />
      </div>
    </div>
  );
};

export default CurrentUserAssessments;
