import React, { Fragment } from 'react';

const PracticeOutcomeBadge = ({ visible }) => {
  if (!visible) return null;

  return (
    <span className="h6 ml-1">
      <span className="badge badge-warning">
        Practice
      </span>
    </span>
  );
};

export const quizFeedback = ({
  score, maxScore, iconOnly = true, isPractice
}) => {
  const quizScore = (score * 100) / maxScore;
  const lowScoreText = 'Please review the material and retake this quiz';
  const text = {
    100: 'Perfect score! Great job on this quiz',
    75: 'Great job! You can see hints for the questions you missed below the question text.',
    50: 'Good work! Check over the questions you missed and try them again.',
    25: lowScoreText,
    0: lowScoreText
  };
  // think the final feedback display should be "You got X out of N correct (Y%)." and then a message:
  if (quizScore === 100) {
    return (
      <Fragment>
        <i className={`fas fa-check-circle text-keppel ${!iconOnly && 'mr-1'}`} aria-hidden="true" />
        {!iconOnly && (<span>{text[100]}</span>)} <PracticeOutcomeBadge visible={isPractice} />
      </Fragment>
    );
  }

  if (quizScore >= 75) {
    return (
      <Fragment>
        <i className={`fas fa-thumbs-up text-keppel ${!iconOnly && 'mr-1'}`} aria-hidden="true" />
        {!iconOnly && (<span>{text[75]}</span>)} <PracticeOutcomeBadge visible={isPractice} />
      </Fragment>
    );
  }

  if (quizScore >= 50) {
    return (
      <Fragment>
        <i className={`fas fa-exclamation-triangle text-warning ${!iconOnly && 'mr-1'}`} aria-hidden="true" />
        {!iconOnly && (<span>{text[50]}</span>)} <PracticeOutcomeBadge visible={isPractice} />
      </Fragment>
    );
  }

  if (quizScore >= 25) {
    return (
      <Fragment>
        <i className={`fas fa-exclamation-circle text-danger ${!iconOnly && 'mr-1'}`} aria-hidden="true" />
        {!iconOnly && (<span>{text[25]}</span>)} <PracticeOutcomeBadge visible={isPractice} />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <i className={`fas fa-times-circle text-danger ${!iconOnly && 'mr-1'}`} aria-hidden="true" />
      {!iconOnly && (<span>{text[0]}</span>)} <PracticeOutcomeBadge visible={isPractice} />
    </Fragment>
  );
};

export const quizTotalScore = ({ score, maxScore }) => {
  return `${Math.round((score / maxScore) * 100)}%`;
};

export const quizAccepted = ({ score, maxScore }) => {
  const quizScore = (score * 100) / maxScore;

  if (quizScore >= 75) return true;

  return false;
};

export const quizTotalPoints = ({ score, maxScore, difficulty }) => { // attempts
  // TODO use attempts to decrease awarded points
  if (score === maxScore) {
    return maxScore * difficulty;
  }

  if (quizAccepted({ score, maxScore })) {
    return score * difficulty;
  }

  return '-';
};

export const classQuizOutcomesEval = ({ quizzes, quizOutcomes }) => { // TODO is this needed, why not consolidate with topicQuizOutcomesEval?
  const quizHash = {};
  const correctOutcomes = [];

  if (!quizzes || (quizzes && Array.isArray(quizzes) && quizzes.length === 0)) {
    // No Quizzes, no need to eval
    // console.log('classQuizOutcomesEval: no quizzes', { quizzes, quizOutcomes });
    return true;
  }

  if (Array.isArray(quizOutcomes) && quizOutcomes.length > 0) {
    quizOutcomes.forEach((outcome) => {
      if (outcome?.id) {
        const {
          id: outcomeId, score, maxScore, quizId
        } = outcome;

        // Quiz key doesn't exist, create key
        if (quizHash[quizId] === undefined) quizHash[quizId] = [];

        if (quizAccepted({ score, maxScore })) {
          quizHash[quizId].push(outcomeId);
        }
      }
    });

    quizzes.forEach((quiz) => {
      if (quizHash[quiz.sys.id] && quizHash[quiz.sys.id].length > 0) {
        correctOutcomes.push([quiz.sys.id, quizHash[quiz.sys.id][0]]); // [quizId, quizOutcome (approved)]
      }
    });

    // Evaluate total topic quizzes against number of accepted QuizOutcomes
    // left side of expression can be greater than if a user had submitted more than one accepted QuizOutcome
    if ((correctOutcomes.length) >= quizzes.length) {
      // Add Topic to user Completed Topics
      // console.log('classQuizOutcomesEval: approved', { quizzes, quizOutcomes });
      return true;
    }
    // Still more Quizzes that need accepted QuizOutcomes
    // console.log('classQuizOutcomesEval: more quizOutcomes needed', { quizzes, quizOutcomes });
    return false;
  }

  // No Quizzes, no need to eval
  // console.log('classQuizOutcomesEval: edgeCase: false', { quizzes, quizOutcomes });

  return false;
};

export const topicQuizOutcomesEval = ({ quizzes, quizOutcomes }) => {
  const quizHash = {};
  const correctOutcomes = [];

  if (!quizzes || (quizzes && Array.isArray(quizzes) && quizzes.length === 0)) {
    // No Quizzes, no need to eval
    // console.log('topicQuizOutcomesEval: no quizzes', { quizzes, quizOutcomes });
    return true;
  }

  if (Array.isArray(quizOutcomes) && quizOutcomes.length > 0) {
    quizOutcomes.forEach((outcome) => {
      if (outcome?.id) {
        const {
          id: outcomeId, score, maxScore, quizId
        } = outcome;

        // Quiz key doesn't exist, create key
        if (quizHash[quizId] === undefined) quizHash[quizId] = [];

        if (quizAccepted({ score, maxScore })) {
          quizHash[quizId].push(outcomeId);
        }
      }
    });

    quizzes.forEach((quiz) => {
      if (quizHash[quiz.sys.id] && quizHash[quiz.sys.id].length > 0) {
        correctOutcomes.push([quiz.sys.id, quizHash[quiz.sys.id][0]]); // [quizId, quizOutcome (approved)]
      }
    });

    // Evaluate total topic quizzes against number of accepted QuizOutcomes
    // left side of expression can be greater than if a user had submitted more than one accepted QuizOutcome
    if ((correctOutcomes.length) >= quizzes.length) {
      // Add Topic to user Completed Topics
      // console.log('topicQuizOutcomesEval: approved', { quizzes, quizOutcomes });
      return true;
    }
    // Still more Quizzes that need accepted QuizOutcomes
    // console.log('topicQuizOutcomesEval: more quizOutcomes needed', { quizzes, quizOutcomes });
    return false;
  }

  // No Quizzes, no need to eval
  // console.log('topicQuizOutcomesEval: edgeCase: false', { quizzes, quizOutcomes });
  return false;
};
